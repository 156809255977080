import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../constants';
import { Button, Input, Modal, Popconfirm, Popover, Spin } from 'antd';
import { ReactSVG } from 'react-svg';
import queryString from 'query-string';
import { selectAllFleets } from '../modules/fleets/stores/slice';
import { doCreateFleet, doGetFleets, doRemoveFleets, doUpdateFleet } from '../modules/fleets/stores/thunk';
import FleetForm from '../components/Forms/FleetForm';
import FleetsTable from '../components/Tables/FleetsTable';

const initStateModal = {
  open: false,
  fleet: null,
  action: 'ide',
};

const FleetsPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isFetching = useSelector((state) => state.fleet.isFetching);
  const status = useSelector((state) => state.fleet.status);
  const action = useSelector((state) => state.fleet.action);
  const metadata = useSelector((state) => state.fleet.metadata);
  const fleets = useSelector((state) => selectAllFleets(state));
  const [searchValue, setSearchValue] = useState('');
  const [searchParams] = useSearchParams();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [stateModal, setStateModal] = useState(initStateModal);
  const [isActionOpen, setIsActionOpen] = useState(false);

  const query = Object.fromEntries(searchParams);
  const currentPage = query.current || 1;
  const itemsPerPage = query.pageSize || PAGE_SIZE;
  const pagination = {
    current: currentPage || 1,
    pageSize: itemsPerPage,
    locale: { items_per_page: 'Fleets/Page' },
    total: metadata.totalItems || fleets.length,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showSizeChanger: true,
    showTotal: (total) => `${total} Fleets`,
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onClickAction = () => {
    selectedRowKeys.length > 0 && setIsActionOpen(!isActionOpen);
  };

  const contentPopoverAction = () => {
    return (
      <div className="buttons">
        <Popconfirm
          title="Are you sure to remove?"
          onConfirm={() => handleClickRemoveFleets()}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link">Remove</Button>
        </Popconfirm>
      </div>
    );
  };

  const handleClickRemoveFleets = () => {
    dispatch(doRemoveFleets({ ids: selectedRowKeys }));
  };

  useEffect(() => {
    if (['create', 'update', 'removeMany']?.includes(action) && status === 'succeeded') {
      dispatch(doGetFleets({ page: currentPage, take: itemsPerPage, ...query }));
      if (isActionOpen) setIsActionOpen(false);
      handleCancelModal();
    }
  }, [isFetching]);

  useEffect(() => {
    setSearchValue(query.q || '');
  }, []);

  useEffect(() => {
    dispatch(doGetFleets({ page: currentPage, take: itemsPerPage, ...query }));
  }, []);

  const handleCancelModal = () => {
    setStateModal(initStateModal);
  };

  const pushTo = (pushProps) => {
    navigate({
      pathname: pushProps.path || location.pathname,
      search: queryString.stringify(pushProps.query || {}, {
        skipNull: true,
      }),
      state: pushProps.state,
    });
  };

  const handleSubmitModal = (data) => {
    if (stateModal.action === 'new') {
      dispatch(doCreateFleet(data));
    } else {
      data.id = stateModal.fleet.id;
      dispatch(doUpdateFleet(data));
    }
  };

  const handleFilter = (params, updatePath) => {
    delete params.pageSizeOptions;
    if (updatePath) {
      pushTo({ query: params });
    }
    const newCurrentPage = Number(params?.current || 1);
    const newItemsPerPage = Number(params?.pageSize) || PAGE_SIZE;
    dispatch(
      doGetFleets({
        page: params.current || newCurrentPage,
        take: newItemsPerPage,
        ...params,
      }),
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch && handleSearch();
    }
  };

  const handleTableChange = (params) => {
    const newParams = { ...query, ...params };
    handleFilter(newParams, true);
  };

  const handleChangeSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = () => {
    handleFilter({ q: searchValue || '' }, true);
  };

  const onClickNew = () => {
    setStateModal({
      ...stateModal,
      open: true,
      fleet: null,
      action: 'new',
    });
  };

  const onClickEdit = (record) => {
    setStateModal({
      ...stateModal,
      open: true,
      fleet: record,
      action: 'edit',
    });
  };

  const withAction = (record) => {
    return (
      <div className="actions">
        <Button type="link" className="edit" onClick={() => onClickEdit(record)}>
          <ReactSVG className="icon" src="/icons/edit.svg" />
        </Button>
        <Button type="link" className="more">
          <ReactSVG className="icon" src="/icons/more.svg" />
        </Button>
      </div>
    );
  };

  return (
    <Spin spinning={isFetching}>
      <div className="FleetsPage Content">
        <div className="ContentHeader">
          <div className="HeaderTitle">Fleets</div>
          <div className="HeaderToolBar">
            <div className="ToolBarButtons">
              <Button className="CreateButton ButtonPrimary" type="primary" size="small" onClick={onClickNew}>
                Create New Fleet
                <ReactSVG className="icon" src="/icons/plus.svg" />
              </Button>
            </div>
          </div>
        </div>
        <div className="page-header-table">
          <div className="search">
            <Input
              onChange={handleChangeSearch}
              onBlur={handleSearch}
              placeholder="Search"
              onKeyDown={handleKeyDown}
              suffix={<img src="/icons/search.svg" />}
              disabled={isFetching}
              value={searchValue}
            />
          </div>
          <div className="buttons">
            <Popover
              open={selectedRowKeys?.length > 0 && isActionOpen}
              content={contentPopoverAction}
              overlayClassName="DriversPagePopoverAction"
              placement="bottom"
            >
              <Button className="FilterButton ButtonFilter" onClick={onClickAction}>
                {selectedRowKeys?.length > 0 && <div className="count">{selectedRowKeys.length}</div>}
                Action <ReactSVG className="icon" src="/icons/arrow_drop_down.svg" />
              </Button>
            </Popover>
          </div>
        </div>

        <div className="ContentMain">
          <FleetsTable
            selectedRowKeys={selectedRowKeys}
            dataSource={fleets}
            pagination={pagination}
            withAction={withAction}
            rowKey="id"
            isFetching={false}
            onSelectChange={onSelectChange}
            onChange={handleTableChange}
          />
        </div>

        <Modal open={stateModal.open} onCancel={handleCancelModal} footer={[]} width={792} forceRender>
          <FleetForm
            handleSubmit={handleSubmitModal}
            handleCancel={handleCancelModal}
            fleet={stateModal.fleet}
            action={stateModal.action}
            isFetching={isFetching}
          />
        </Modal>
      </div>
    </Spin>
  );
};

export default FleetsPage;
