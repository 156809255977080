import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  doDispatchRoutes,
  doGetRoutes,
  doGetRoutesByDate,
  doOptimizeRoutesByDate,
  doOptimizeRoutesGmproByDate,
  doReorderOrdersForRoutes,
} from './thunk';

const isActionWithPending = (action) => {
  return action.type && action.type.startsWith('Routes/') && action.type.endsWith('/pending');
};

const isActionWithFulfilled = (action) => {
  return action.type && action.type.startsWith('Routes/') && action.type.endsWith('/fulfilled');
};

const isActionWithFailed = (action) => {
  return action.type && action.type.startsWith('Routes/') && action.type.endsWith('/rejected');
};

const routesAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const routeSlice = createSlice({
  name: 'route',
  initialState: {
    action: null,
    isFetching: false,
    status: 'idle',
    metadata: {},
    entities: [],
    ids: [],
    item: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetRoutesByDate.pending, (state) => {
      state.action = 'getAllByDate';
      state.status = 'loading';
      routesAdapter.setAll(state, []);
    });
    builder.addCase(doGetRoutesByDate.fulfilled, (state, action) => {
      routesAdapter.setAll(state, action.payload);
    });
    builder.addCase(doGetRoutesByDate.rejected, (state) => {
      state.status = 'failed';
      routesAdapter.setAll(state, []);
    });
    builder.addCase(doGetRoutes.pending, (state) => {
      state.action = 'getAll';
      state.status = 'loading';
      routesAdapter.setAll(state, []);
    });
    builder.addCase(doGetRoutes.fulfilled, (state, action) => {
      const { routes, pagination } = action.payload;
      state.status = 'succeeded';
      state.metadata = pagination;
      routesAdapter.setAll(state, routes);
    });
    builder.addCase(doGetRoutes.rejected, (state) => {
      state.status = 'failed';
      routesAdapter.setAll(state, []);
    });
    builder.addCase(doOptimizeRoutesByDate.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doOptimizeRoutesByDate.pending, (state) => {
      state.action = 'optimizeByDate';
      state.status = 'loading';
    });
    builder.addCase(doOptimizeRoutesByDate.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doOptimizeRoutesGmproByDate.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doOptimizeRoutesGmproByDate.pending, (state) => {
      state.action = 'optimizeByDate';
      state.status = 'loading';
    });
    builder.addCase(doOptimizeRoutesGmproByDate.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doDispatchRoutes.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doDispatchRoutes.pending, (state) => {
      state.action = 'dispatchByDate';
      state.status = 'loading';
    });
    builder.addCase(doDispatchRoutes.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doReorderOrdersForRoutes.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doReorderOrdersForRoutes.pending, (state) => {
      state.action = 'reorder';
      state.status = 'loading';
    });
    builder.addCase(doReorderOrdersForRoutes.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addMatcher(isActionWithPending, (state) => {
      state.isFetching = true;
      state.message = null;
    });
    builder.addMatcher(isActionWithFulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addMatcher(isActionWithFailed, (state) => {
      state.isFetching = false;
      state.message = null;
    });
  },
});

export const {
  selectAll: selectAllRoutes,
  selectById: selectRouteById,
  selectIds: selectRouteIds,
} = routesAdapter.getSelectors((state) => state.route);
const { reducer: routeReducer } = routeSlice;
export default routeReducer;
