import React, { useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';

import UnScheduledSteps from './UnScheduledSteps';
import { getListStyle } from '../../utils/common';
import Icons from '../../icons';

const UnScheduled = ({
  zoom,
  steps = [],
  realSteps,
  stepsHovered,
  setStepsHovered,
  onClickStep,
  onHoverStep,
  onZoomIn,
  onZoomOut,
  handleCloseStepPopup,
  isDragging,
  selectedMultipleSteps,
  multipleStepState,
}) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <div className="tr">
      <div className="td">
        <div className="driver-name">
          <b>Unscheduled ({steps?.length || 0})</b>
        </div>
      </div>
      <div className="td" style={{ width: '100%' }}>
        {isMounted && (
          <Droppable droppableId="unscheduled" direction="horizontal">
            {(provided, snapshot) => (
              <div
                className={`scrollCustom unscheduled-steps ${isDragging && 'isDragging'}`}
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  maxWidth: 'calc(100vw - 710px)',
                  minWidth: '12px',
                  display: 'flex',
                  paddingRight: '10px',
                  alignItems: 'center',
                  height: '40px',
                  overflow: 'auto',
                  zIndex: 999,
                  ...getListStyle(isDragging, snapshot.isDraggingOver),
                }}
              >
                <UnScheduledSteps
                  steps={steps.reduce((array, item) => array.concat(item), [])}
                  realSteps={realSteps}
                  stepsHovered={stepsHovered}
                  setStepsHovered={setStepsHovered}
                  onClickStep={onClickStep}
                  onHoverStep={onHoverStep}
                  handleCloseStepPopup={handleCloseStepPopup}
                  multipleStepState={multipleStepState}
                  selectedMultipleSteps={selectedMultipleSteps}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </div>
      <div className="td">
        <div className="actions">
          <Icons className={zoom >= 1500 ? 'disabled' : ''} onClick={onZoomIn} type="ZoomIn" />
          <Icons className={zoom <= 300 ? 'disabled' : ''} onClick={onZoomOut} type="ZoomOut" />
        </div>
      </div>
    </div>
  );
};

export default UnScheduled;
