import { Table } from 'antd';
import React from 'react';
import moment from 'moment';
import { DAY_FORMAT, DAYTIME_FORMAT } from '../../constants';

export default function RoutesTable({ withAction, dataSource, selectedRowKeys, onSelectChange, ...props }) {
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 100,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      fixed: 'left',
      width: 200,
      className: 'border-right',
      render: (value) => {
        return value ? moment(value).format(DAY_FORMAT) : '';
      },
    },
    {
      title: 'Driver',
      dataIndex: 'driverName',
      key: 'driverName',
      render: (_va, record) => {
        return record?.driver?.name || '-';
      },
    },
    {
      title: '# of stops',
      key: '# of stops',
      render: (record) => {
        return record?._count?.steps || 0;
      },
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
      render: (value) => {
        return value ? `${(value / 1000).toFixed(2)} km` : '';
      },
    },
    {
      title: 'Travel Time',
      dataIndex: 'travelMins',
      key: 'travelMins',
      render: (value) => {
        return value ? `${(value / 60).toFixed(2)} hr` : '';
      },
    },
    {
      title: 'Working Time',
      dataIndex: 'workingMins',
      key: 'workingMins',
      render: (value) => {
        return value ? `${(value / 60).toFixed(2)} hr` : '';
      },
    },
    {
      title: 'Service Time',
      dataIndex: 'serviceMins',
      key: 'serviceMins',
      render: (value) => {
        return value ? `${(value / 60).toFixed(2)} hr` : '';
      },
    },
    {
      title: 'Working %',
      dataIndex: 'workingPercent',
      key: 'workingPercent',
      render: (value) => {
        return value ? `${value}%` : '';
      },
    },
    {
      title: 'Visits',
      key: 'Visits',
      render: (record) => {
        return record?._count?.orders ? `${record?._count?.orders * 2}` : '0';
      },
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 250,
      render: (value) => {
        return value ? moment(value).format(DAYTIME_FORMAT) : '';
      },
    },
  ];

  if (withAction) {
    columns.push({
      key: 'action',
      width: 120,
      fixed: 'right',
      render(record) {
        return withAction(record);
      },
      className: 'border-left',
    });
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className="RoutesTable table-common">
      <Table
        columns={columns}
        rowSelection={rowSelection}
        dataSource={dataSource}
        // bordered
        {...props}
      />
    </div>
  );
}
