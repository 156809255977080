import { Table } from 'antd';
import React from 'react';
import moment from 'moment';
import { COLORS, DAYTIME_FORMAT, ORDER_STATUSES_DISPLAY, STEP_TYPE } from '../../constants';

export default function RouteOrdersTable({
  dataSource,
  selectedRowKeys,
  onSelectChange,
  showIndexVisit,
  routes,
  solutions,
  ...props
}) {
  const columns = [
    {
      title: 'Order ID',
      dataIndex: 'orderCode',
      key: 'orderCode',
      fixed: 'left',
      width: 200,
      className: 'border-right',
      render: (value, record) => {
        if (showIndexVisit) {
          const route = routes.find((route) => route.id === record.routeId);
          const driverId = route?.driver?.id || 'unscheduled';
          const pickupIndex = solutions[driverId]?.findIndex(
            (s) => s.type === STEP_TYPE.pickup && (s.orderId === record.id || s.orderIds?.includes(record.id)),
          );
          const deliveryIndex = solutions[driverId]?.findIndex(
            (s) => s.type === STEP_TYPE.delivery && s.orderId === record.id,
          );
          const color = route?.driver?.id ? COLORS[route.driver.id % (COLORS.length - 1)] : '606060';

          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{
                  border: `1px solid #${color}`,
                  color: `#${color}`,
                  textAlign: 'center',
                  minWidth: '22px',
                  minHeight: '22px',
                  lineHeight: '20px',
                  borderRadius: '50%',
                  fontSize: '11px',
                  letterSpacing: -1,
                  paddingRight: 1,
                }}
              >
                {pickupIndex + 1}
              </span>
              <span
                style={{
                  border: `1px solid #${color}`,
                  color: `#${color}`,
                  textAlign: 'center',
                  minWidth: '22px',
                  minHeight: '22px',
                  lineHeight: '20px',
                  borderRadius: 4,
                  fontSize: '11px',
                  marginLeft: '6px',
                  marginRight: '3px',
                  letterSpacing: -1,
                  paddingRight: 1,
                }}
              >
                {deliveryIndex + 1}
              </span>
              {value}
            </div>
          );
        } else {
          return value;
        }
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 250,
      render: (value) => {
        return value ? moment(value).format(DAYTIME_FORMAT) : '';
      },
    },
    {
      title: 'Pickup Address',
      dataIndex: 'pickupAddress',
      key: 'pickupAddress',
      width: 700,
    },
    {
      title: 'Delivery Address',
      dataIndex: 'deliveryAddress',
      key: 'deliveryAddress',
      width: 700,
    },
    {
      title: 'Delivery Phone',
      dataIndex: 'deliveryPhone',
      key: 'deliveryPhone',
      width: 200,
    },
    {
      title: 'Pickup Time Window',
      dataIndex: 'pickupTimeWindow',
      width: 200,
      ellipsis: true,
      render(_value, record) {
        return (record.pickupTimeStart || '') + ' - ' + (record.pickupTimeEnd || '');
      },
    },
    {
      title: 'Delivery Time Window',
      dataIndex: 'deliveryTimeWindow',
      width: 200,
      ellipsis: true,
      render(_value, record) {
        return (record.deliveryTimeStart || '') + ' - ' + (record.deliveryTimeEnd || '');
      },
    },
    {
      title: 'Load',
      dataIndex: 'load',
      key: 'load',
      width: 200,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      fixed: 'right',
      width: 200,
      className: 'border-left',
      render: (value) => {
        return <span className={'status order ' + value.toLowerCase()}>{ORDER_STATUSES_DISPLAY[value]}</span>;
      },
    },
  ];

  if (showIndexVisit) {
    columns.splice(1, 0, {
      title: 'Driver Name',
      key: 'Driver Name',
      width: 200,
      render: (record) => {
        const route = routes.find((route) => route.id === record.routeId);
        return route?.driver?.name || '';
      },
    });
    columns.splice(1, 0, {
      title: 'Route ID',
      key: 'Route ID',
      width: 200,
      render: (record) => {
        const route = routes.find((route) => route.id === record.routeId);
        return route?.id || '';
      },
    });
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className="table-orders table-common">
      <Table
        columns={columns}
        rowSelection={rowSelection}
        dataSource={dataSource}
        scroll={{ x: 'max-content', y: 'max-content' }}
        pagination={false}
        // bordered
        {...props}
      />
    </div>
  );
}
